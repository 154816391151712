import React, { useContext } from 'react';
import styled from 'styled-components';
import ResponsiveContext from '../../context/ResponsiveContext';
import Button from '../../components/Button/Button';
import Box, { BoxContainer } from '../../components/Box/Box';

const ErrorBox = styled(Box)`
  height: ${props => props.isMobile ? '200px' : '300px'};
  width: ${props => props.isMobile ? '50%' : '500px'};
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorHeader = styled.h1`
  font-size: ${props => props.isMobile ? props.theme.fontSize.xxxl : props.theme.fontSize.error};
  color: ${props => props.theme.colors.text.error};
  margin-top: ${props => props.isMobile ? 10 : 0}px;
  margin-bottom: ${props => props.isMobile ? 10 : 30}px;
`;

const ErrorSubHeader = styled.h2`
  font-size: ${props => props.theme.fontSize.m};
  color: ${props => props.theme.colors.text.subHeader};
  text-align: center;
  margin-top: ${props => props.isMobile ? 0 : 10}px;
  margin-bottom: 10px;
`;

const ErrorDescription = styled.h3`
  font-size: ${props => props.theme.fontSize.s};
  color: ${props => props.theme.colors.text.description};
  text-align: center;
  margin-top: 0px;
  margin-bottom: ${props => props.isMobile ? 10 : 50}px;
`;

const Error = ({ 
  action, 
  actionLabel,
  header,
  subHeader,
  description,
}) => {
  const { isMobile } = useContext(ResponsiveContext)

  return (
    <BoxContainer>
      <ErrorBox isMobile={isMobile}>
        <ErrorHeader isMobile={isMobile}>
          {header}
        </ErrorHeader>
        <ErrorSubHeader isMobile={isMobile}>
          {subHeader}
        </ErrorSubHeader>
        <ErrorDescription isMobile={isMobile}>
          {description}
        </ErrorDescription>
        {
          action && 
          <Button
            onClick={action}
            label={actionLabel}
          />
        }
      </ErrorBox>
    </BoxContainer>
  );
};

export default Error;