import React, { useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import { BoxContainer } from "../../../components/Box/Box";
import Box from "../../../components/Box/Box";
import Button from "../../../components/Button/Button";
import OTPinput from "react-otp-input";
import "./loader.css";
import BPILogo from "../../../assets/BPILogo.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const FormTitleContainerLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 50px;
  padding: 50px;
`;

const FormTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  font-size: 20px;
  border-bottom: 2px solid grey;
  margin-bottom: 10px;
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 400px;
  height: ${(props) => props.height || "auto"};
`;

const TextProcessing = styled.h3`
  text-align: center;
`;

const Text = styled.text`
  text-align: center;
  padding: 10px;
`;

const TextResend = styled.button`
  text-align: center;
  padding: 10px;
  padding: 0;
  border: none;
  background: none;
  font-size: 15px;
  cursor: pointer;
`;

const TextMiddle = styled.h3`
  text-align: center;
  padding: 5px;
`;

const ClickableButton = styled(Button)`
  padding: 5px;
`;

const ButtonConfimation = styled.div`
  padding: 10px;
  margin-top: 50px;
  text-align: center;
`;

const OTP = styled(OTPinput)`
  padding-left: 2px;
`;

const BPIotp = () => {
  const location = useLocation();
  const history = useHistory();

  const [gatewayCheckerURL] = useState(process.env.REACT_APP_API_URL);
  const [otp, setOTP] = useState({ otp: "" });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [successUrl] = useState(
    window.localStorage.getItem("urlSuccessResponse")
  );
  const [failedUrl] = useState(
    window.localStorage.getItem("urlFailedResponse")
  );

  const handleChange = (otp) => setOTP({ otp });

  const modalClose = () => setShow(false);

  const load = (
    <center>
      <h1 class="loader" />
    </center>
  );

  //SUBMIT
  function submitPayment() {
    setLoading(true);
    fetch(`${gatewayCheckerURL}/api/bpi/callback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Method": "POST",
        Authorization: "Bearer " + location.state.accessToken,
      },
      body: JSON.stringify({
        request: {
          otp: otp.otp,
          transactionId: location.state.transactionId,
          paymentId: location.state.paymentId,
          accessToken: location.state.access_token,
        },
      }),
    })
      .then((result) => result.json())
      .then((response) => {
        if (
          response.description ===
          "You have entered an invalid One-Time PIN. Please try again"
        ) {
          setLoading(false);
          setMessage(response.description);
          setShow(true);
        } else if (
          response.description === "Your One-Time PIN expired. Please try again"
        ) {
          setLoading(false);
          setMessage(response.description);
          setShow(true);
        } else if (response.transactionStatus === "SUCCESSFUL") {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("amount");
          window.localStorage.removeItem("paymentId");
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("urlFailedResponse");
          window.localStorage.removeItem("urlSuccessResponse");
          window.open(successUrl, "_self");
        } else {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("amount");
          window.localStorage.removeItem("paymentId");
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("urlFailedResponse");
          window.localStorage.removeItem("urlSuccessResponse");
          window.open(failedUrl, "_self");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //RESEND
  function sendOtp() {
    fetch(`${gatewayCheckerURL}/api/command`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Method": "POST",
        Authorization: "Bearer " + location.state.accessToken,
      },
      body: JSON.stringify({
        command: {
          name: "PostOtp",
          payload: {
            accessToken: location.state.access_token,
            transactionId: location.state.transactionId,
            mobileNumberToken: location.state.mobileNumberToken,
          },
        },
      }),
    })
      .then((result) => result.json())
      .then((response) => response);
  }

  return (
    <BoxContainer>
      {!loading ? (
        <FormBox>
          <FormTitleContainer>Authentication</FormTitleContainer>
          <Text>
            Your 6-digit authentication code was sent to your registered mobile
            number
          </Text>
          <TextMiddle>{location.state.mobileNumber}</TextMiddle>
          <Text>Please enter the authentication code.</Text>
          <div style={{ padding: "0 80px" }}>
            <OTP
              inputStyle={{
                width: "2rem",
                height: "2rem",
                margin: "30px .2rem",
                fontSize: "1rem",
                borderRadius: 6,
                border: "1px solid",
              }}
              onChange={handleChange}
              numInputs={6}
              shouldAutoFocus
              value={otp.otp}
              isInputNum={true}
            />
          </div>
          <center>
            <Text>Didn’t get the code?</Text>{" "}
            <TextResend style={{ color: "#318CE0" }} onClick={sendOtp}>
              Tap here to resend
            </TextResend>
          </center>

          <Modal open={show} onClose={modalClose} center={true}>
            <p>{message}</p>
          </Modal>

          <ButtonConfimation>
            <ClickableButton
              onClick={submitPayment}
              disabled={Object.keys(otp.otp).length < 6 ? true : false}
            >
              SUBMIT
            </ClickableButton>
          </ButtonConfimation>
        </FormBox>
      ) : (
        <FormBox>
          <FormTitleContainerLogo>
            <img src={BPILogo} height={70} />
          </FormTitleContainerLogo>
          <TextProcessing>Payment Processing</TextProcessing>
          <Text>Please do not exit this page.</Text>
          {load}
        </FormBox>
      )}
    </BoxContainer>
  );
};

export default BPIotp;
