import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const CheckboxIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #9e9e9e;

  transition: all 0.15s ease;

  ::after {
    position: absolute;
    display: none;
    content: '';
  }

  &:after {
    top: 1px;
    left: 4px;
    width: 3px;
    height: 8px;
    transform: rotate(45deg);
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
  }
`;

const StyledCheckbox = styled.label`
  height: 16px;
  width: 16px;
  position: relative;
  display: block;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;

  input[type='checkbox'] {
    position: absolute;
    display: none;

    &:disabled ~ ${CheckboxIndicator} {
      background-color: #dddddd;
      pointer-events: none;
    }

    &:checked ~ ${CheckboxIndicator} {
      background-color: #359be0;
      border-color: #359be0;

      ::after {
        display: block;
      }
    }

    &:disabled:checked ~ ${CheckboxIndicator} {
      filter: opacity(0.8);
    }
  }
`;

const Checkbox = ({
  name,
  value,
  disabled,
  checked,
  defaultChecked,
  onChange,
  disableClick,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(
    checked || defaultChecked || false
  );

  useEffect(() => {
    if (checked !== isChecked) setIsChecked(checked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  function handleOnChange(event) {
    event.stopPropagation();
    if (checked !== undefined) {
      setIsChecked(!isChecked);
    }
    if (onChange !== undefined) {
      onChange(event);
    }
  }
  return (
    <StyledCheckbox
      className={className}
      disabled={disabled}
      onClick={event => {
        if (disableClick) {
          event.preventDefault();
        }
      }}
    >
      <input
        name={name}
        type="checkbox"
        disabled={disabled}
        checked={isChecked ? isChecked : false}
        value={value}
        onChange={handleOnChange}
      />
      <CheckboxIndicator />
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disableClick: PropTypes.bool,
  className: PropTypes.string,
};

export default Checkbox;
