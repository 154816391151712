import React, { useContext } from 'react';
import FormModal from '../../components/Form/FormModal';
import { useLocation } from 'react-router';
import AuthenticationContext from '../../context/AuthenticationContext';
import { BoxContainer } from '../../components/Box/Box';
import { FIELD_TYPES } from '../../components/Form';
import * as Yup from 'yup';

const Adyen = () => {
  const { setToken } = useContext(AuthenticationContext);
  const location = useLocation();

  if(!location.state.paymentSession) {
    setToken(null);
  }

  return (
    <BoxContainer>
      <FormModal
        isOpen={true}
        width="400px"
        title="Adyen"
        instructions={
          <span>
            **
          </span>
        }
        submitText="Submit"
        handleSubmit={values => {
          console('DONE');
        }}
        fields={{
          paymentSession: {
            type: FIELD_TYPES.TEXTAREA,
            label: 'Payment Session',
            placeholder: 'SAMPLE_TOKEN_#@DSDAS#@_SDSV_==',
            validation: Yup.string().required('Please select a value'),
            required: true,
            initialValue: location.state.paymentSession,
          }
        }}
      />
    </BoxContainer>
  );
}

export default Adyen;