import React from 'react';
import Error from './Error';


const Error404 = () => {

  return (
    <Error
      header={'ERROR 404'}
      subHeader={`Please make sure you have the valid url.`}
      description={`Ooops... You're lost, the page you're looking for does not exist!`}
    />
  );
};

export default Error404;