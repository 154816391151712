import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import AuthenticationContext from "../../../context/AuthenticationContext";
import { BoxContainer } from "../../../components/Box/Box";
import Box from "../../../components/Box/Box";
import Button from "../../../components/Button/Button";
import BPILogo from "../../../assets/BPILogo.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const FormTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 50px;
  margin-bottom: 10px;
  padding: 50px;
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 400px;
  height: ${(props) => props.height || "auto"};
`;

const TextConfimation = styled.text`
  text-align: center;
  padding: 5px;
`;

const TextConfimationAmount = styled.h3`
  text-align: center;
  padding: 5px;
`;

const ClickableButton = styled(Button)`
  padding: 10px;
`;

const ButtonConfimation = styled.div`
  padding: 10px;
  margin-top: 50px;
  text-align: center;
`;

const BPIConfimation = () => {
  const { accessToken, setAccessToken } = useContext(AuthenticationContext);
  const location = useLocation();
  const history = useHistory();
  // setAccessToken(location.state.accessToken);
  const [show, setShow] = useState(false);

  const [gatewayCheckerURL] = useState(process.env.REACT_APP_API_URL);

  const modalClose = () => setShow(false);

  function nextPage() {
    fetch(`${gatewayCheckerURL}/api/command`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Method": "POST",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        command: {
          name: "PostInitiate",
          payload: {
            accessToken: location.state.access_token,
            accountNumberToken: location.state.accountNumberToken,
            paymentId: location.state.paymentId,
            amount: location.state.amount,
          },
        },
      }),
    })
      .then((result) => result.json())
      .then((response) => {
        if (
          response.error &&
          response.error.details.description ===
            "We are unable to process your transaction at this time"
        ) {
          setShow(true);
        } else if (
          response.data &&
          response.data.body.description === "Success"
        ) {
          history.push({
            pathname: "/initiateotp",
            search:
              "?token=" + location.state.token + "&at=" + location.state.token,
            state: {
              access_token: location.state.access_token,
              transactionId: response.data.headers.transactionId,
              mobileNumber: response.data.body.body.mobileNumber,
              mobileNumberToken: response.data.body.body.mobileNumberToken,
              paymentId: location.state.paymentId,
              token: location.state.token,
              accessToken: location.state.accessToken,
            },
          });
        }
      });
  }

  function numberWithCommas(number, fractionDigits = 0) {
    return parseFloat(number).toLocaleString(navigator.language, {
      minimumFractionDigits: fractionDigits,
    });
  }

  return (
    <BoxContainer>
      <FormBox>
        <FormTitleContainer>
          <img src={BPILogo} height={70} />
        </FormTitleContainer>
        <TextConfimation>You are about to pay</TextConfimation>
        <TextConfimationAmount>
          PHP {numberWithCommas(location.state.amount, 2)}
        </TextConfimationAmount>
        <TextConfimation>using your BPI account</TextConfimation>
        <ButtonConfimation>
          <ClickableButton onClick={nextPage}>Confirm</ClickableButton>
        </ButtonConfimation>

        <Modal open={show} onClose={modalClose} center={true}>
          <p>We are unable to process your transaction at this time</p>
        </Modal>
      </FormBox>
    </BoxContainer>
  );
};

export default BPIConfimation;
