import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  background-color: ${props => props.theme.colors.button.bg.primary};
  color: ${props => props.theme.colors.button.fg};
  font-size: ${props => props.theme.fontSize.m};
  padding: 0px 20px;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;

  &:disabled {
    background-color: ${props => props.theme.colors.button.bg.disabled};
    cursor: default;
  }
`;

const Button = ({ disabled, children, onClick }) => {

  return (
    <ButtonContainer disabled={disabled} onClick={onClick}>
      {children}
    </ButtonContainer>
  )
};

export default Button;