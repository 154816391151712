import { useEffect, useState } from 'react';
import usePrevious from './usePrevious';

const initialValues = fields =>
  Object.keys(fields).reduce(
    (values, name) => ({
      ...values,
      [name]: fields[name].initialValue,
    }),
    {}
  );

const initialErrors = fields =>
  Object.keys(fields).reduce(
    (errors, name) => ({
      ...errors,
      [name]: null,
    }),
    {}
  );

/**
 * fields shape
 *
 *  {
 *    name: {
 *     initialValue: <any>,
 *     validation: Yup.<any>(),
 *     onChange: (value) => {}
 *    }
 *    ...
 *  }
 */

function useForm(fields, onSubmit) {
  // initialize values from fields
  const [values, setValues] = useState(initialValues(fields));

  // get a handler for the previous values, we need this to check for validations
  const prevValues = usePrevious(values);

  // intialize error state for each field
  // will contain error message
  const [errors, setErrors] = useState(initialErrors(fields));

  const [isFormValid, setIsFormValid] = useState(false);

  function validate(name) {
    const validation =
      typeof fields[name].validation === 'object'
        ? fields[name].validation
        : fields[name].validation(values);

    return validation
      .validate(values[name])
      .then(() => {
        if (errors[name]) {
          setErrors({ ...errors, [name]: null });
        }
      })
      .catch(err => {
        if (err.errors.length) {
          setErrors({ ...errors, [name]: err.errors[0] });
        }
      });
  }

  // onChange for each field
  // if Synthetic event is passed as value, will automatically extract the real value from the event
  const onChange = Object.keys(fields).reduce(
    (onChanges, name) => ({
      ...onChanges,
      [name]: event => {
        let value = event;
        if (event && event.target && event.target.value !== undefined) {
          value = event.target.value;
        }
        setValues({ ...values, [name]: value });
      },
    }),
    {}
  );

  // initialize onBlur callbacks for each field
  // triggers touched to also trigger validation
  const onBlur = Object.keys(fields).reduce(
    (onBlurs, name) => ({
      ...onBlurs,
      [name]: () => {
        if (fields[name].validation) {
          validate(name);
        }
      },
    }),
    {}
  );

  const checkIsFormValid = async () => {
    for (const name of Object.keys(fields)) {
      if (fields[name].validation) {
        try {
          const validation =
            typeof fields[name].validation === 'object'
              ? fields[name].validation
              : fields[name].validation(values);
          await validation.validate(values[name]);
        } catch (err) {
          if (isFormValid) {
            setIsFormValid(false);
          }
          return;
        }
      }
    }
    if (!isFormValid) {
      setIsFormValid(true);
    }
  };

  // checks for changes in value
  useEffect(() => {
    // checks for which field was changed, then capture the name of that field
    let name = null;
    for (const key in values) {
      if (
        prevValues &&
        JSON.stringify(values[key]) !== JSON.stringify(prevValues[key])
      ) {
        name = key;
        break;
      }
    }

    // catch case if no name was found
    if (name) {
      if (fields[name].validation) {
        validate(name);
      }

      if (fields[name].onChange) {
        fields[name].onChange(values);
      }
    }

    // check for errors in every field to know validity of whole form
    checkIsFormValid();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)]);

  function reset() {
    setValues(initialValues(fields));
    setTimeout(() => {
      setErrors(initialErrors(fields));
    }, 0);
  }

  useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fields)]);

  async function handleOnSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const names = Object.keys(fields);

    const newErrors = {};
    for (const name of names) {
      if (fields[name].validation) {
        try {
          const validation =
            typeof fields[name].validation === 'object'
              ? fields[name].validation
              : fields[name].validation(values);
          await validation.validate(values[name]);
        } catch (err) {
          if (err && err.errors.length) {
            newErrors[name] = err.errors[0];
          }
        }
      }
    }

    if (!Object.keys(newErrors).length) {
      onSubmit(values);
    } else {
      setErrors({ ...errors, ...newErrors });
    }
  }

  useEffect(() => {
    checkIsFormValid();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    values,
    setValues,
    onChange,
    onBlur,
    errors,
    reset,
    isFormValid,
    onSubmit: handleOnSubmit,
  };
}

export default useForm;
