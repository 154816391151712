import React, { useEffect, useState, useContext } from "react";
import AuthenticationContext from "../../context/AuthenticationContext";
import { BoxContainer } from "../../components/Box/Box";
// import { BounceLoader } from 'react-spinners';
import { FIELD_TYPES } from "../../components/Form";
import FormModal from "../../components/Form/FormModal";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

const GATEWAYS = {
  ADYEN: "adyen",
  GCASH: "gcash",
  IPAY88: "ipay88",
  BPI: "bpi",
};

const Initial = () => {
  const history = useHistory();
  const { token, setToken, accessToken, code, setAccessToken } = useContext(
    AuthenticationContext
  );

  setAccessToken(accessToken);
  setToken(token);

  const [gatewayCheckerURL, setGatewayCheckerURL] = useState(
    process.env.REACT_APP_API_URL
  );
  // eslint-disable-next-line no-unused-vars
  const [adyenPayload, setAdyenPayload] = useState(null);

  function ipay88Request(data) {
    const { checkoutUrl, requestParameters } = data;
    let form = document.createElement("form");
    form.method = "POST";
    form.action = checkoutUrl;

    Object.keys(requestParameters).map((key) => {
      let element = document.createElement("input");

      element.value = requestParameters[key];
      element.name = key;

      form.appendChild(element);

      return key;
    });

    document.body.appendChild(form);

    form.submit();
  }

  function adyenRequest(paymentSession) {
    const sdkConfigObj = {
      context: "test",
    };

    window.chckt.checkout(
      { paymentSession: paymentSession },
      "#adyen",
      sdkConfigObj
    );

    window.chckt.hooks.beforeComplete = (node, paymentData) => {
      setAdyenPayload(paymentData.payload);

      return true;
    };
  }

  if (code) {
    history.push({
      pathname: "/accounts/",
      search: "?code=" + code,
    });
  }

  useEffect(() => {
    if (accessToken) {
      if (gatewayCheckerURL) {
        fetch(`${gatewayCheckerURL}/api/command`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Method": "POST",
            Authorization: "Bearer " + accessToken,
          },
          body: JSON.stringify({
            command: {
              name: "GetDetails",
              payload: {
                token,
                accessToken,
              },
            },
          }),
        })
          .then((result) => result.json())
          .then((response) => {
            const { data } = response;
            if (data.gatewayProcessor === GATEWAYS.GCASH) {
              window.open(data.checkoutUrl, "_self");
            } else if (data.gatewayProcessor === GATEWAYS.BPI) {
              window.open(data.checkoutUrl, "_self");
              window.localStorage.setItem(
                "amount",
                data.paymentDetails.amountValue
              );
              window.localStorage.setItem(
                "paymentId",
                data.paymentDetails.paymentId
              );
              window.localStorage.setItem("token", token);
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem(
                "urlFailedResponse",
                data.paymentDetails.urlFailedResponse
              );
              window.localStorage.setItem(
                "urlSuccessResponse",
                data.paymentDetails.urlSuccessResponse
              );
            } else if (data.gatewayProcessor === GATEWAYS.ADYEN) {
              adyenRequest(data.paymentSession);
            } else if (data.gatewayProcessor === GATEWAYS.IPAY88) {
              ipay88Request(data);
            } else {
              setToken(null);
            }
          })
          .catch((error) => {
            console.log("ERROR: " + error);
            setToken(null);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayCheckerURL]);

  return (
    <BoxContainer>
      {gatewayCheckerURL ? null : (
        <FormModal
          isOpen={true}
          width="400px"
          // handleClose={() =>
          //   setState({ ...state, isLeavingPageWhileAdding: true })
          // }
          title="URL Tester"
          instructions={
            <span>To test your url kindly select a gateway to be tested</span>
          }
          submitText="Submit"
          handleSubmit={(values) => {
            setGatewayCheckerURL(values.testURL);
          }}
          fields={{
            testURL: {
              type: FIELD_TYPES.TOGGLE,
              options: [
                {
                  value: process.env.REACT_APP_ADYEN_TEST_URL,
                  label: "Adyen",
                  color: "#009cde",
                },
                {
                  value: process.env.REACT_APP_GCASH_TEST_URL,
                  label: "GCash",
                  color: "#009cde",
                },
                {
                  value: process.env.REACT_APP_IPAY88_TEST_URL,
                  label: "iPay88",
                  color: "#009cde",
                },
              ],
              label: "Gateway URL",
              placeholder: "Adyen/GCash/iPay88",
              validation: Yup.string().required("Please select a value"),
              required: true,
              initialValue: process.env.REACT_APP_ADYEN_TEST_URL,
            },
          }}
        />
      )}
      <div id="adyen" />
    </BoxContainer>
  );
};

export default Initial;
