import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import AuthenticationContext from "../../../context/AuthenticationContext";
import { BoxContainer } from "../../../components/Box/Box";
import Box from "../../../components/Box/Box";
import Button from "../../../components/Button/Button";

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 400px;
  padding: 10px;
  height: ${(props) => props.height || "auto"};
`;

const ListofAccounts = styled.li`
  list-style-type: none;
`;

const BorderList = styled.ul`
  border-bottom: 1px solid;
  font-size: 15px;
  color: grey;
  margin-top: -10px;
  padding: 0 0 0 0;
`;

const TextAccountType = styled.text`
  margin-left: 20px;
`;

const TextMyBPIAccounts = styled.h3`
  border-bottom: 1px solid;
`;

const TextAmountLabel = styled.h2`
  padding-left: 10px;
`;

const TextAmount = styled.h3`
  margin-top: -5px;
  padding: 0 10px 0 10px;
  border: 1px;
  border-style: solid;
  box-sizing: border-box;
  width: 200px;
`;

const BPIAccounts = () => {
  const { accessToken, setAccessToken, token, setToken } = useContext(
    AuthenticationContext
  );
  const history = useHistory();
  const location = useLocation();
  setToken(window.localStorage.getItem("token"));
  setAccessToken(window.localStorage.getItem("accessToken"));

  const query = new URLSearchParams(location.search);
  const urlCode = query.get("code");

  const [gatewayCheckerURL] = useState(process.env.REACT_APP_API_URL);
  const [state, setState] = useState({
    accounts: [],
    access_token: null,
    accountNumberToken: null,
    amount: window.localStorage.getItem("amount"),
    paymentId: window.localStorage.getItem("paymentId"),
    loading: true,
  });

  function onChangeAccounts(event) {
    setState({
      ...state,
      accountNumberToken: event.target.value,
    });
  }

  function nextPage() {
    history.push({
      pathname: "/confirmation",
      search: "?token=" + token + "&at=" + accessToken,
      state: {
        amount: state.amount,
        accountNumberToken: state.accountNumberToken,
        access_token: state.access_token,
        paymentId: state.paymentId,
        accessToken: accessToken,
        token: token,
      },
    });
  }

  useEffect(() => {
    if (urlCode) {
      fetch(`${gatewayCheckerURL}/api/bpi/oauth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Method": "POST",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify({
          request: {
            code: urlCode,
          },
        }),
      })
        .then((result) => result.json())
        .then((response) =>
          setState({
            ...state,
            access_token: response.postTokenResponse.body.access_token,
          })
        );
    }
  }, []);

  useEffect(() => {
    if (state.access_token) {
      fetch(`${gatewayCheckerURL}/api/command`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Method": "POST",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify({
          command: {
            name: "PostToken",
            payload: {
              accessToken: state.access_token,
            },
          },
        }),
      })
        .then((result) => result.json())
        .then((response) => {
          setState({
            ...state,
            loading: false,
            accounts: response.data,
            accountNumberToken: response.data[0].accountNumberToken,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.access_token]);

  function numberWithCommas(number, fractionDigits = 0) {
    return parseFloat(number).toLocaleString(navigator.language, {
      minimumFractionDigits: fractionDigits,
    });
  }

  return (
    <BoxContainer>
      <FormBox>
        <TextAmountLabel>Amount to Pay</TextAmountLabel>
        <TextAmount>PHP {numberWithCommas(state.amount, 2)}</TextAmount>
        <TextMyBPIAccounts>MY BPI ACCOUNTS</TextMyBPIAccounts>
        {state.accounts.map((data) => {
          return (
            <>
              <BorderList>
                <ListofAccounts>
                  <input
                    type="radio"
                    name="accounts"
                    value={data.accountNumberToken}
                    onChange={onChangeAccounts}
                    checked
                  />
                  {data.accountNumber}
                </ListofAccounts>
                <TextAccountType>{data.accountType}</TextAccountType>
              </BorderList>
            </>
          );
        })}
        <Button onClick={nextPage} disabled={state.loading}>
          Next
        </Button>
      </FormBox>
    </BoxContainer>
  );
};

export default BPIAccounts;
