import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import AuthenticationContext from "../../../context/AuthenticationContext";
import { BoxContainer } from "../../../components/Box/Box";
import Box from "../../../components/Box/Box";
import Button from "../../../components/Button/Button";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const FormTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  font-size: 20px;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 400px;
  height: ${(props) => props.height || "auto"};
`;

const Text = styled.text`
  text-align: center;
  padding: 10px;
`;

const TextMiddle = styled.h3`
  text-align: center;
  padding: 10px;
`;

const ClickableButton = styled(Button)`
  padding: 10px;
`;

const ButtonConfimation = styled.div`
  padding: 10px;
  margin-top: 50px;
  text-align: center;
`;

const BPIotpinitiate = () => {
  const location = useLocation();
  const history = useHistory();

  const [gatewayCheckerURL] = useState(process.env.REACT_APP_API_URL);
  const [show, setShow] = useState(false);
  const [failedUrl] = useState(
    window.localStorage.getItem("urlFailedResponse")
  );

  const modalClose = () => setShow(false);

  function sendOtp() {
    fetch(`${gatewayCheckerURL}/api/command`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Method": "POST",
        Authorization: "Bearer " + location.state.accessToken,
      },
      body: JSON.stringify({
        command: {
          name: "PostOtp",
          payload: {
            accessToken: location.state.access_token,
            transactionId: location.state.transactionId,
            mobileNumberToken: location.state.mobileNumberToken,
          },
        },
      }),
    })
      .then((result) => result.json())
      .then((response) => {
        if (
          response.error &&
          response.error.details.description ===
            "We are unable to process your transaction at this time"
        ) {
          setShow(true);
        } else if (
          response.data &&
          response.data.body.description === "Success"
        ) {
          history.push({
            pathname: "/otp",
            search:
              "?token=" +
              location.state.token +
              "&at=" +
              location.state.accessToken,
            state: {
              access_token: location.state.access_token,
              transactionId: location.state.transactionId,
              mobileNumberToken: location.state.mobileNumberToken,
              paymentId: location.state.paymentId,
              mobileNumber: location.state.mobileNumber,
              accessToken: location.state.accessToken,
            },
          });
        } else {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("amount");
          window.localStorage.removeItem("paymentId");
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("urlFailedResponse");
          window.localStorage.removeItem("urlSuccessResponse");
          window.open(failedUrl, "_self");
        }
      });
  }

  return (
    <BoxContainer>
      <FormBox>
        <FormTitleContainer>Authentication</FormTitleContainer>
        <Text>
          BPI will send a 6-digit authentication code via a SMS to your BPI
          registered mobile number. Please review before proceeding.
        </Text>
        <TextMiddle>{location.state.mobileNumber}</TextMiddle>
        <Text>is this your number?</Text>
        <ButtonConfimation>
          <ClickableButton onClick={sendOtp}>SEND CODE</ClickableButton>
        </ButtonConfimation>

        <Modal open={show} onClose={modalClose} center={true}>
          <p>We are unable to process your transaction at this time</p>
        </Modal>
      </FormBox>
    </BoxContainer>
  );
};

export default BPIotpinitiate;
