import React, { useState } from 'react';

const AuthenticationContext = React.createContext({});

export const AuthenticationProvider = ({ children }) => {
  const [token, setToken, ] = useState(null);
  const [accessToken, setAccessToken, ] = useState(null);
  const [code, setCode ] = useState(null);

  return (
    <AuthenticationContext.Provider
      value={{
        code,
        setCode,
        token,
        setToken,
        accessToken, 
        setAccessToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
};

export default AuthenticationContext;