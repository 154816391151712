import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ClickOutHandler from 'react-onclickout';
import styled from 'styled-components/macro';
import Checkbox from '../Checkbox';
import Row from '../Row';

export const DropdownContainer = styled.div`
  color: #4a4a4a;
  position: relative;
`;

export const DropdownButton = styled.button`
  outline: none;
  min-height: 30px;
  position: relative;
  color: #4a4a4a;
  padding: 0 24px 0 12px;
  text-align: left;

  &:disabled {
    background-color: #eeeeee;
    cursor: default;
  }

  width: 100%;
  border: 1px solid #26aeef;
  border-radius: 2px;
  background-color: white;
  cursor: pointer;
`;

export const DropdownMultiValue = styled.div`
  background-color: #0090e1;
  height: 100%;
  padding: 5px;
  border-radius: 2px;
  color: #ffffff;
  font-size: ${props => props.theme.fontSize.xs};
  margin: 3px;

  &:last-child {
    margin-right: 0;
  }
`;

export const DropdownArrow = styled.span`
  position: absolute;
  top: calc(50% - 3px);
  right: 7px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: ${props => (props.isRenderingDown ? 0 : '7px solid #a4a4a4')};
  border-top: ${props => (props.isRenderingDown ? '7px solid #a4a4a4' : 0)};
`;

export const DropdownMenu = styled.div`
  border: 1px solid #26aeef;
  border-radius: 2px;
  background-color: red;
  cursor: pointer;
  position: absolute;
  z-index: 2000;
  max-height: 200px;
  overflow-y: auto;
  min-width: 100%;

  top: ${props =>
    props.isRenderingDown ? props.containerHeight + 1 + 'px' : 'initial'};
  bottom: ${props =>
    props.isRenderingDown ? 'initial' : props.containerHeight + 1 + 'px'};
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;

  cursor: ${props => (props.empty ? 'default' : 'pointer')};

  &:hover {
    background-color: ${props => (props.empty ? 'transparent' : '#d6f3ff')};
  }
`;

const Dropdown = ({
  placeholder = 'Placeholder',
  className,
  options,
  defaultValue,
  value,
  onChange,
  onBlur,
  name,
  disabled,
  multi,
  showMulti = false,
}) => {
  const dropdownContainer = useRef(null);
  const dropdownMenu = useRef(null);

  // flag for showing menu
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  // flag whether to render the menu up or down
  const [isRenderingDown, setIsRenderingDown] = useState(true);

  // value of the dropdown itself, can be anything
  const [dropdownValue, setDropdownValue] = useState(
    value !== undefined
      ? value
      : defaultValue !== undefined
      ? defaultValue
      : multi
      ? []
      : null
  );

  // height of the dropdown container, used for positioning the menu
  const [containerHeight, setContainerHeight] = useState(0);

  // listen for value prop changes, and adjust dropdownValue state accordingly
  useEffect(() => {
    setDropdownValue(value !== undefined ? value : multi ? [] : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // listen for dropdownValue changes, adjust containerHeight accordingly
  useEffect(() => {
    setContainerHeight(dropdownContainer.current.clientHeight);
  }, [dropdownValue]);

  // everytime menu shows up, we calculate if it should render down or up
  useEffect(() => {
    if (isShowingMenu) {
      let element = dropdownMenu.current;
      const height = dropdownMenu.current.clientHeight;

      let y = 0;

      while (element) {
        y += element.offsetTop;
        element = element.offsetParent;
      }

      setIsRenderingDown(y + height < window.document.body.clientHeight);
    }
  }, [isShowingMenu]);

  // useForm requires an onBlur listener, we'll call it if we click out off the dropdown menu
  function triggerFakeBlur() {
    const e = { target: { value, name } };
    if (onBlur) {
      onBlur(e);
    }
  }

  function handleSelect(value) {
    let newValue = value;
    if (multi) {
      const isFound = dropdownValue.find(v => v === value);
      newValue = isFound
        ? dropdownValue.filter(v => v !== value)
        : [...dropdownValue, value];
    }

    if (!value) {
      setDropdownValue(newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
    if (!multi) {
      setIsShowingMenu(false);
    }
  }

  let buttonText = placeholder;

  if (multi && showMulti) {
    buttonText = (
      <Row>
        {dropdownValue.map(value => {
          const option = options.find(option => option.value === dropdownValue);
          return option ? (
            <DropdownMultiValue key={value}>{option.label}</DropdownMultiValue>
          ) : null;
        })}
      </Row>
    );
  } else {
    const option = options.find(option => option.value === dropdownValue);
    if (option) buttonText = option.label;
  }

  return (
    <DropdownContainer ref={dropdownContainer} className={className}>
      <DropdownButton
        disabled={disabled}
        type="button"
        onClick={() => setIsShowingMenu(!isShowingMenu)}
      >
        {buttonText}
        {!disabled && <DropdownArrow isRenderingDown={isRenderingDown} />}
      </DropdownButton>
      {isShowingMenu && (
        <ClickOutHandler
          onClickOut={() => {
            triggerFakeBlur();
            setIsShowingMenu(false);
          }}
        >
          <DropdownMenu
            ref={dropdownMenu}
            isRenderingDown={isRenderingDown}
            containerHeight={containerHeight}
          >
            {options.length ? (
              options.map((option, index) => {
                const label =
                  typeof option === 'object' ? option.label : option;
                const value =
                  typeof option === 'object' ? option.value : option;

                return (
                  <DropdownMenuItem
                    key={index}
                    onClick={() => handleSelect(value)}
                  >
                    {label}
                    {multi && (
                      <Checkbox
                        disableClick
                        name={value}
                        checked={!!dropdownValue.find(v => v === value)}
                        onChange={() => {}}
                      />
                    )}
                  </DropdownMenuItem>
                );
              })
            ) : (
              <DropdownMenuItem empty>No options</DropdownMenuItem>
            )}
          </DropdownMenu>
        </ClickOutHandler>
      )}
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.any.isRequired,
        disabled: PropTypes.string,
      }),
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ])
  ).isRequired,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  showMulti: PropTypes.bool,
};

export default Dropdown;
