import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ResponsiveContext from '../../context/ResponsiveContext';
import usePrevious from '../../hooks/usePrevious';
import Checkbox from '../Checkbox';
import Dropdown from '../Dropdown';
import { DropdownButton, DropdownMenu } from '../Dropdown/Dropdown';
import Row from '../Row';
import FIELD_TYPES from './constants';
import FormFieldToggle from '../Toggle/Toggle';

export const StyledFormField = styled.div`
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  justify-content: ${props => (props.row ? 'space-between' : 'initial')};
  align-items: ${props => (props.row ? 'center' : 'initial')};

  width: 100%;
  margin-bottom: ${props => props.verticalGap || 5}px;
`;

export const FormFieldLabel = styled.label`
  font-size: ${props => props.theme.fontSize.s};
  margin-bottom: 5px;
  color: ${props =>
    props.error ? '#ff3e56' : props.disabled ? '#979797' : 'inherit'};
`;

export const FormFieldInput = styled.input`
  padding: 3px 12px;
  height: 30px;
  margin-bottom: 3px;
  font-weight: 300;
  border: 0;
  border-bottom: 1px solid ${props => (props.error ? '#ff3e56' : '#979797')};
  font-size: ${props => props.theme.fontSize.s};
  width: 100%;
  padding-left: ${props => (props.isMobileNumber ? 40 : 12)}px;

  transition: border 0.15s ease;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &:disabled {
    filter: opacity(0.8);
    background-color: white;
  }

  &:focus {
    border-bottom: 1px solid #009bdd;
  }
`;

const FormFieldTextArea = styled.textarea`
  height: 100px;
  border: 1px solid ${props => (props.error ? '#ff3e56' : '#979797')};

  &:focus {
    border: 1px solid #009bdd;
  }
`;

export const FormFieldDropdown = styled(Dropdown)`
  ${DropdownButton} {
    font-weight: 300;
    font-size: ${props => props.theme.fontSize.s};
    border: 0;
    border-bottom: 1px solid ${props => (props.error ? '#ff3e56' : '#979797')};

    transition: border 0.15s ease;
    color: rgb(84, 84, 84);
    filter: ${props =>
      props.error || !props.value ? 'opacity(0.8)' : 'opacity(1)'};

    &:focus {
      border-bottom: 1px solid #009bdd;
    }

    &:disabled {
      background-color: transparent;
      filter: opacity(0.8);
    }
  }
  ${DropdownMenu} {
    font-size: ${props => props.theme.fontSize.s};
    border: 1px solid #979797;
  }
`;

export const FormFieldCheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 120px;
  flex-wrap: wrap;
`;

export const FormFieldCheckboxRow = styled(Row)`
  height: 30px;
  margin-right: 10px;
  color: #333333;
  font-size: ${props => props.theme.fontSize.s};
`;

export const FormFieldCheckboxGroupLabel = styled.div`
  margin-left: 10px;
  font-size: ${props => props.theme.fontSize.xs};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const MobileNumberPrefix = styled.div`
  z-index: 10;
  left: 12px;
  font-size: ${props => props.theme.fontSize.s};
  position: absolute;
  height: 30px;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
`;

export const PercentNumberSuffix = styled.div`
  z-index: 10;
  right: 10px;
  font-size: ${props => props.theme.fontSize.s};
  position: absolute;
  height: 30px;
  top: 8px;
`;

export const Required = styled.span`
  color: #ff3e56;
`;

export const FormFieldError = styled.div`
  margin-left: 5px;
  color: #ff3e56;
  font-size: 10px;
  height: 12px;
`;

export const FormFieldStatic = styled.div`
  font-size: ${props => props.theme.fontSize.s};
  color: #0072ce;
  padding-left: 12px;
  margin-top: ${props => (props.row ? 0 : 10)}px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: pre;
`;

const FormField = ({
  label,
  error,
  type,
  required,
  placeholder,
  name,
  readOnly,
  onChange,
  onBlur,
  value,
  options,
  horizontalGap = 40,
  verticalGap = 20,
  perRow = 1,
  row = false,
  noErrors = false,
  isStatic = false,
  className,
  defaultValue = '',
  isPercent = false,
}) => {
  const [, forceUpdate] = useState();

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (value !== prevValue) {
      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { isMobile } = useContext(ResponsiveContext);

  return (
    <StyledFormField
      perRow={isMobile ? 1 : perRow}
      horizontalGap={isMobile ? 0 : horizontalGap}
      verticalGap={verticalGap}
      row={row}
      className={className}
    >
      {label && (
        <FormFieldLabel error={required && !!error} disabled={readOnly}>
          {required && !readOnly && <Required>*</Required>}
          {label}
        </FormFieldLabel>
      )}
      {isStatic && <FormFieldStatic row={row}>{value}</FormFieldStatic>}
      {[
        FIELD_TYPES.TEXT,
        FIELD_TYPES.PASSWORD,
        FIELD_TYPES.EMAIL,
        FIELD_TYPES.NUMBER,
        FIELD_TYPES.MOBILE_NUMBER,
      ].includes(type) && (
        <RelativeContainer>
          {type === FIELD_TYPES.MOBILE_NUMBER && (
            <MobileNumberPrefix>+63</MobileNumberPrefix>
          )}
          <FormFieldInput
            isMobileNumber={type === FIELD_TYPES.MOBILE_NUMBER}
            isPercentNumber={isPercent}
            type={FIELD_TYPES.MOBILE_NUMBER === type ? FIELD_TYPES.TEXT : type}
            step={'any'}
            placeholder={placeholder}
            value={value || defaultValue}
            name={name}
            disabled={readOnly}
            onChange={event => {
              if (
                type === FIELD_TYPES.NUMBER &&
                event.target.value === '' &&
                event
              ) {
                onChange({ target: { value: 0 } });
              } else {
                onChange(event);
              }
            }}
            onFocus={event => {
              if (type === FIELD_TYPES.NUMBER) {
                event.target.select();
              }
            }}
            onBlur={onBlur}
          />
          {isPercent && <PercentNumberSuffix>%</PercentNumberSuffix>}
        </RelativeContainer>
      )}
      {type === FIELD_TYPES.TEXTAREA && 
        <FormFieldTextArea 
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          disabled={readOnly}
        />
      }
      {(type === FIELD_TYPES.SELECT || type === FIELD_TYPES.MULTISELECT) && (
        <FormFieldDropdown
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          options={options}
          disabled={readOnly}
          placeholder={placeholder}
          error={required && !!error}
          multi={type === FIELD_TYPES.MULTISELECT}
        />
      )}
      {type === FIELD_TYPES.CHECKBOX && (
        <Checkbox
          name={name}
          onChange={() => onChange(!value)}
          checked={value}
          disabled={readOnly}
        />
      )}
      {type === FIELD_TYPES.TOGGLE && (
        <FormFieldToggle
          name={name}
          onChange={onChange}
          options={options}
          value={value}
          disabled={readOnly}
        />
      )}
      {type === FIELD_TYPES.CHECKBOX_GROUP && (
        <FormFieldCheckboxGroup>
          {options.map((option, index) => {
            const checked = value.includes(option.value);
            return (
              <FormFieldCheckboxRow key={index}>
                <Checkbox
                  name={`${name}.${option.value}`}
                  value={option.value}
                  checked={checked}
                  disabled={readOnly}
                  onChange={() => {
                    onChange(
                      value.includes(option.value)
                        ? value.filter(v => v !== option.value)
                        : [...value, option.value]
                    );
                  }}
                />
                <FormFieldCheckboxGroupLabel disabled={readOnly}>
                  {option.label}
                </FormFieldCheckboxGroupLabel>
              </FormFieldCheckboxRow>
            );
          })}
        </FormFieldCheckboxGroup>
      )}
      {!noErrors && !isStatic && (
        <FormFieldError>{!readOnly && error}</FormFieldError>
      )}
    </StyledFormField>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  horizontalGap: PropTypes.number,
  verticalGap: PropTypes.number,
  row: PropTypes.bool,
  noErrors: PropTypes.bool,
  perRow: PropTypes.number,
  isStatic: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  isPercent: PropTypes.bool,
};

export default FormField;
