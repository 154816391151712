const theme = {
  breakpoint: {
    phone: '1024px',
  },
  fontSize: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '24px',
    xl: '26px',
    xxl: '28px',
    xxxl: '32px',
    error: '72px',
  },
  colors: {
    main: {
      bg: '#CCC',
      fg: '#333',
    },
    text: {
      header: '#000',
      subHeader: '#333',
      description: '#555',
      error: '#F66',
    },
    button: {
      bg: {
        primary: '#009cde',
        disabled: '#333',
      },
      fg: '#FFF',
    },
  },
};

export default theme;
