import React, { useContext } from 'react';
import { BoxContainer } from '../../components/Box/Box';
import FormModal from '../../components/Form/FormModal';
import AuthenticationContext from '../../context/AuthenticationContext';
import { useLocation } from 'react-router';
import { FIELD_TYPES } from '../../components/Form';
import styled from 'styled-components';


const IPayContainer = styled(BoxContainer)`
  flex-direction: column;
`;


const Ipay88 = () => {
  const { setToken } = useContext(AuthenticationContext);
  const location = useLocation();
  const params = location.state.data;


  if(!params) {
    setToken(null);
  }

  return (
    <IPayContainer>
      <FormModal
        isOpen={true}
        title={'iPay88 Payment'}
        actionURL={params.checkoutUrl}
        width="400px"
        height="400px"
        instructions={
          //TODO
          <span>
            **
          </span>
        }
        submitText="Submit"
        handleSubmit={values => {
          console('DONE');
        }}
        fields={{
          MerchantCode: {
            type: FIELD_TYPES.TEXT,
            label: 'Merchant Code',
            placeholder: 'Merchant Code',
            initialValue: params.requestParameters.MerchantCode,
          },
          ActionType: {
            type: FIELD_TYPES.TEXT,
            label: 'Action Type',
            placeholder: 'Action Type',
            initialValue: params.requestParameters.ActionType,
          },
          PaymentId: {
            type: FIELD_TYPES.TEXT,
            label: 'Payment ID',
            placeholder: 'Payment ID',
            initialValue: params.requestParameters.PaymentId,
          },
          RefNo: {
            type: FIELD_TYPES.TEXT,
            label: 'Reference Number',
            placeholder: 'Reference Number',
            initialValue: params.requestParameters.RefNo,
          },
          Amount: {
            type: FIELD_TYPES.NUMBER,
            label: 'Amount',
            placeholder: 'Amount',
            initialValue: params.requestParameters.Amount,
          },
          Currency: {
            type: FIELD_TYPES.TEXT,
            label: 'Currency',
            placeholder: 'Currency',
            initialValue: params.requestParameters.Currency,
          },
          ProdDesc: {
            type: FIELD_TYPES.TEXT,
            label: 'Product Description',
            placeholder: 'Product Description',
            initialValue: params.requestParameters.ProdDesc,
          },
          UserName: {
            type: FIELD_TYPES.TEXT,
            label: 'User Name',
            placeholder: 'User Name',
            initialValue: params.requestParameters.UserName,
          },
          UserEmail: {
            type: FIELD_TYPES.EMAIL,
            label: 'User Email',
            placeholder: 'User Email',
            initialValue: params.requestParameters.UserEmail,
          },
          UserContact: {
            type: FIELD_TYPES.TEXT,
            label: 'User Contact',
            placeholder: 'User Contact',
            initialValue: params.requestParameters.UserContact,
          },
          Lang: {
            type: FIELD_TYPES.TEXT,
            label: 'Lang',
            placeholder: 'Lang',
            initialValue: params.requestParameters.Lang,
          },
          Signature: {
            type: FIELD_TYPES.TEXT,
            label: 'Signature',
            placeholder: 'Signature',
            initialValue: params.requestParameters.Signature,
          },
          ResponseURL: {
            type: FIELD_TYPES.TEXT,
            label: 'Response URL',
            placeholder: 'Response URL',
            initialValue: params.requestParameters.ResponseURL,
          },
          BackendURL: {
            type: FIELD_TYPES.TEXT,
            label: 'Backend URL',
            placeholder: 'Backend URL',
            initialValue: params.requestParameters.BackendURL,
          },
          TokenId: {
            type: FIELD_TYPES.TEXT,
            label: 'Token ID',
            placeholder: 'Token ID',
            initialValue: params.requestParameters.TokenId,
          },
        }}
      />
    </IPayContainer>
  );
};

export default Ipay88;