import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import theme from '../theme';

const ResponsiveContext = React.createContext({});

const PHONE_BREAKPOINT = +theme.breakpoint.phone.slice(0, -2);

export const ResponsiveProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= PHONE_BREAKPOINT
  );

  function handleResize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const screenIsMobile = window.innerWidth <= PHONE_BREAKPOINT;
    if(screenIsMobile !== isMobile) {
      setIsMobile(screenIsMobile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <ResponsiveContext.Provider value={{ isMobile }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

ResponsiveProvider.propTypes = {
  children: PropTypes.element,
};

export default ResponsiveContext;
