import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const ToggleContainerStyle = styled.div`
  height: 30px;
  border-radius: 5px;
  border-width: 2px;
  border-color: ${props => props.modeColor};
  border-style: solid;
  display: flex;
`;

const ToggleButtonStyle = styled.button`
  background-color: ${props =>
    props.isSelected ? props.toggleColor : 'white'};
  font-size: ${props => props.theme.fontSize.s};
  color: ${props => (props.isSelected ? 'white' : '#333333')};
  border-width: 0px;
  border-right: 2px solid ${props => props.borderColor};
  flex: 1;
  cursor: pointer;

  &:first-child {
    border-radius: ${props => (props.isSelected ? '0px' : '5px 0px 0px 5px')};
  }

  &:last-child {
    border-radius: ${props => (props.isSelected ? '0px' : '0px 5px 5px 0px')};
    border-width: 0px;
  }
`;

const Toggle = ({
  name,
  value,
  disabled,
  onChange,
  disableClick,
  options,
  className,
}) => {
  const [toggleValue, setToggleValue] = useState(value);

  useEffect(() => {
    if (value !== toggleValue) setToggleValue(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleSelect(newValue) {
    if (!disabled) {
      setToggleValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  }

  const modeColor =
    toggleValue !== undefined
      ? options.find(option => {
          return option.value === toggleValue;
        }).color
      : options[0].color;

  return (
    <ToggleContainerStyle modeColor={modeColor}>
      {options.map(option => {
        const isSelected = toggleValue === option.value;
        return (
          <ToggleButtonStyle
            key={option.value}
            toggleColor={option.color}
            borderColor={modeColor}
            isSelected={isSelected}
            onClick={() => handleSelect(option.value)}
          >
            {option.label}
          </ToggleButtonStyle>
        );
      })}
    </ToggleContainerStyle>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  disableClick: PropTypes.bool,
  className: PropTypes.string,
};

export default Toggle;
