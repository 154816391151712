export default {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  PASSWORD: 'password',
  NUMBER: 'number',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkboxGroup',
  DATE: 'date',
  DATE_RANGE: 'dateRange',
  MONTH_RANGE: 'monthRange',
  YEAR_RANGE: 'yearRange',
  YTD: 'ytd',
  TOGGLE: 'toggle',
};
