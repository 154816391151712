import React, { useEffect, useContext } from "react";
import { useLocation, Route } from "react-router";
import AuthenticationContext from "../../context/AuthenticationContext";
import { Error404 } from "../../pages/Error";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    token,
    setToken,
    accessToken,
    setAccessToken,
    code,
    setCode,
  } = useContext(AuthenticationContext);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryCode = query.get("code");
    const queryToken = query.get("token");
    const queryAccessToken = query.get("at");

    if (queryToken) {
      setToken(queryToken);
      setAccessToken(queryAccessToken);
    }

    if (queryCode) {
      setCode(queryCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) =>
        (token && accessToken) || code ? (
          <Component {...props} />
        ) : (
          <Route component={Error404} />
        )
      }
    />
  );
};

export default PrivateRoute;
