import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import useForm from '../../hooks/useForm';
import Button from '../Button';
import FormField from './FormField';
import Row from '../Row';
import Box from '../Box/Box';

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: ${props => props.width};
  height: ${props => props.height || 'auto'};
`;

const FormTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.button.bg.primary};
  margin-bottom: 10px;
`;

const FormTitle = styled.h1`
  color: ${props => props.theme.colors.button.bg.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  text-align: center;
  margin: 0px;
`;

const Instructions = styled.div`
  margin-bottom: 20px;
  font-size: ${props => props.theme.fontSize.s};
`;

const ButtonContainer = styled(Row)`
  justify-content: center;
  margin: 20px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-height: -webkit-fill-available;
`;

const FormRow = styled(Row)`
  flex: 1;
  overflow: auto;
`;

const FormModal = ({
  instructions,
  actionURL = null,
  fields,
  submitText,
  changeListener,
  handleSubmit,
  height,
  width,
  title,
}) => {
  const {
    values,
    setValues,
    onChange,
    onBlur,
    errors,
    onSubmit,
    isFormValid,
  } = useForm(fields, handleSubmit);

  return (
    <FormBox width={width} height={height}>
      {
        title && 
        <FormTitleContainer>
          <FormTitle>{title}</FormTitle>
        </FormTitleContainer>
      }
      <Form onSubmit={actionURL ? undefined : onSubmit} action={actionURL}>
        {instructions && <Instructions>{instructions}</Instructions>}
        <FormRow>
          {Object.keys(fields).map((name, index) => (
            <FormField
              key={name}
              name={name}
              type={fields[name].type}
              label={fields[name].label}
              placeholder={fields[name].placeholder}
              value={values[name]}
              onChange={
                changeListener
                  ? data => {
                      onChange[name](data);
                      changeListener(name, data, values, setValues, fields);
                    }
                  : onChange[name]
              }
              onBlur={onBlur[name]}
              error={errors[name]}
              required={
                fields[name].required ||
                (fields[name].requiredWhen
                  ? fields[name].requiredWhen(values)
                  : false)
              }
              options={
                fields[name].dynamicOptions
                  ? fields[name].dynamicOptions(values)
                  : fields[name].options
              }
              isPercent={fields[name].isPercent}
              horizontalGap={
                typeof fields[name].horizontalGap === undefined
                  ? 20
                  : fields[name].horizontalGap
              }
              perRow={1}
              defaultValue={
                fields[name].defaultValue !== undefined
                  ? fields[name].defaultValue
                  : ''
              }
              readOnly={
                fields[name].readOnly ||
                (fields[name].disableWhen
                  ? fields[name].disableWhen(values)
                  : false)
              }
            />
          ))}
        </FormRow>
        <ButtonContainer>
          <Button disabled={!isFormValid} type="submit">
            {submitText}
          </Button>
        </ButtonContainer>
      </Form>
    </FormBox>
  );
};

FormModal.propTypes = {
  instructions: PropTypes.any,
  fields: PropTypes.object,
  changeListener: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitText: PropTypes.string,
};

export default FormModal;
