import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import GlobalStyle from './GlobalStyle';
import Router from './router';
import { ResponsiveProvider } from './context/ResponsiveContext';
import { AuthenticationProvider } from './context/AuthenticationContext';

function App() {

  return (
    <AuthenticationProvider>
      <ResponsiveProvider>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Router/>
          </>
        </ThemeProvider>
      </ResponsiveProvider>
    </AuthenticationProvider>
  );
}

export default App;
