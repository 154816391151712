import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Initial from "./pages/Initial";
import Adyen from "./pages/Adyen";
import { Error404 } from "./pages/Error";
import PrivateRoute from "./components/PrivateRoute";
import Ipay88 from "./pages/Ipay88/Ipay88";
import accounts from "./pages/Bpi/BPIaccounts/index";
import confirmation from "./pages/Bpi/BPIconfirmation/index";
import initiateotp from "./pages/Bpi/BPIotpinitiate/index";
import otp from "./pages/Bpi/BPIotp/index";
// import successPayment from "./pages/Bpi/PaymentSuccess/index";
// import failedPayment from "./pages/Bpi/PaymentFailed/index";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={Initial} />
        <PrivateRoute exact path="/Adyen" component={Adyen} />
        <PrivateRoute exact path="/Ipay88" component={Ipay88} />
        <PrivateRoute exact path="/confirmation" component={confirmation} />
        <PrivateRoute exact path="/accounts" component={accounts} />
        <PrivateRoute exact path="/initiateotp" component={initiateotp} />
        <PrivateRoute exact path="/otp" component={otp} />
        {/* <Route exact path="/success" component={successPayment} />
        <Route exact path="/failed" component={failedPayment} /> */}
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
