import { createGlobalStyle } from 'styled-components/macro';
import fontBold from './assets/fonts/FSElliotPro-Bold.ttf';
import fontLighter from './assets/fonts/FSElliotPro-Light.ttf';
import fontNormal from './assets/fonts/FSElliotPro.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'FSElliotPro';
    src: url(${fontNormal}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'FSElliotPro';
    src: url(${fontBold}) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'FSElliotPro';
    src: url(${fontLighter}) format('truetype');
    font-weight: 300;
  }

  * {
    font-family: 'FSElliotPro', sans-serif;
    box-sizing: border-box;
    outline: none;
  }

  body {
    background-color: #f9f9f9;
    margin: 0;
  }

  .react-datepicker-popper {
    z-index: 2000;
  }
`;

export default GlobalStyle;
